import { useEffect, useState } from 'react'
import type { ApiFetcher } from '../api-fetcher'
import { createApiFetcher } from '../api-fetcher'
import { useAuthenticationToken } from './use-authentication-token'

export type UseApiFetcherOptions = {
  /**
   * Function used to create an {@link ApiFetcher}. Defaults to the real
   * implementation of the {@link ApiFetcher} interface, but supplied here for
   * easy mocking in unit tests.
   */
  readonly createApiFetcherFunction?: typeof createApiFetcher

  /**
   * Contains the current window location origin. Defaults to `window.origin`,
   * but supplied here for easy mocking in unit testing.
   */
  readonly locationOrigin?: string

  /**
   * Contains a mechanism to interact with the browser's localStorage. Defaults
   * to using localStorage, but supplied here for easy mocking in unit tests.
   */
  readonly storage?: Storage
}

export function useApiFetcher({
  createApiFetcherFunction = createApiFetcher,
  locationOrigin = window.location.origin,
  storage = localStorage,
}: UseApiFetcherOptions = {}): ApiFetcher | undefined {
  const { token: apiToken } = useAuthenticationToken(storage)
  const [apiFetcher, setApiFetcher] = useState<ApiFetcher | undefined>(
    apiToken ? createApiFetcherFunction({ apiToken, baseUrl: locationOrigin }) : undefined,
  )

  // Create a new ApiFetcher once there is a valid token or clear an existing
  // ApiFetcher if the token is cleared.
  useEffect(() => {
    if (apiToken) {
      const apiFetcher = createApiFetcherFunction({ apiToken, baseUrl: locationOrigin })
      setApiFetcher(apiFetcher)
    } else {
      setApiFetcher(undefined)
    }
  }, [apiToken, locationOrigin, createApiFetcherFunction])

  return apiFetcher
}
