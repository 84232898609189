import { SharedAccess, SharedAccessChange, User, UserList } from '../generated/api/public_api'
import { createConsoleLogger, type Logger } from './logger'

type Signal = Readonly<AbortSignal>
type FetchHeaders = Readonly<Record<string, string>>

/**
 * Contains options about content that is paged, expressed as query parameters
 * in the GET request.
 */
export type PagedContentOptions = {
  /**
   * How many items to return (rowsPerPage).
   */
  readonly limit: number

  /**
   * The offset into the total to start returning (page * rowsPerPage).
   */
  readonly offset: number
}

/**
 * Small wrapper around API calls to the Django-based Hub server. When adding
 * new API calls, try to keep them 1:1 with the actual Django API.
 */
export type ApiFetcher = {
  readonly getAuthUser: (signal: Signal) => Promise<User>
  readonly getJobSharedAccess: (jobId: string, signal: Signal) => Promise<SharedAccess>
  readonly getUsers: (pagedContentOptions: PagedContentOptions, signal: Signal) => Promise<UserList>

  readonly patchJobSharedAccess: (
    jobId: string,
    addEmails: readonly string[],
    removeEmails: readonly string[],
    signal: Signal,
  ) => Promise<void>
}

/**
 * Represents the fetch function that will be used at runtime to make API calls.
 * The native `fetch` function is used in production and a mocked version is
 * used in unit tests.
 */
export type FetchFunction = (url: Readonly<Request>, init?: Readonly<RequestInit>) => Promise<Response>

/**
 * Contains options to control the functionality of an {@link ApiFetcher}.
 */
export type ApiFetcherCreateOptions = {
  /**
   * Contains the API token to use when authenticating with the Django server.
   */
  readonly apiToken: string

  /**
   * Contains the URL root pointing to the Django API server.
   */
  readonly baseUrl: Readonly<URL> | string

  /**
   * Contains the implementation of how to do the underlying fetch. In
   * production, this uses the native `fetch` function and in unit tests a
   * mocked version is used.
   */
  readonly fetchFunction?: FetchFunction

  /**
   * Optional logger. By default a console logger will be used.
   */
  readonly logger?: Logger
}

/**
 * Creates an object that implements the {@link ApiFetcher} interface using the
 * supplied options.
 *
 * Exposed as a separate function rather than a class for two reasons:
 * 1. To accomodate unit tests being able to supply a mock implementation (which
 *    can also be done via a class).
 * 2. Classes can be problematic in JavaScript (using `typeof` across execution
 *    boundaries). Classes are fine for an implementation of an interface, just
 *    not great to expose as THE interface.
 *
 * @param options - Options that control the behavior of the fetching.
 * @returns An object impelementing the {@link ApiFetcher} interface.
 */
export function createApiFetcher(options: ApiFetcherCreateOptions): ApiFetcher {
  return new ApiFetcherImpl(options)
}

type SearchParameter = [key: string, value: string]

/**
 * Implements the {@link ApiFetcher} type by adding authorization headers, error
 * handling, and Protobuf deserialization.
 */
class ApiFetcherImpl implements ApiFetcher {
  public readonly apiToken: string
  public readonly baseUrl: URL
  public readonly fetchFunction: FetchFunction
  public readonly logger: Logger

  public constructor({
    apiToken,
    baseUrl,
    fetchFunction,
    logger = createConsoleLogger({ contextName: 'ApiFetcherImpl' }),
  }: ApiFetcherCreateOptions) {
    this.apiToken = apiToken
    this.baseUrl = new URL(baseUrl)
    this.fetchFunction = fetchFunction ?? /* istanbul ignore next */ fetch.bind(window)
    this.logger = logger
  }

  public async getAuthUser(signal: Readonly<AbortSignal>): Promise<User> {
    const user = await this.executeGet({
      relativePath: 'users/auth/user',
      signal,
      protobufDecodeFunction: (buffer) => User.decode(buffer),
    })
    return user
  }

  public async getJobSharedAccess(jobId: string, signal: Signal): Promise<SharedAccess> {
    const sharedAccess = await this.executeGet({
      relativePath: `jobs/${jobId}/shared_access`,
      signal,
      protobufDecodeFunction: (buffer) => SharedAccess.decode(buffer),
    })
    return sharedAccess
  }

  public async getUsers(pagedContentOptions: PagedContentOptions, signal: Signal): Promise<UserList> {
    const { limit, offset } = pagedContentOptions
    const userList = await this.executeGet({
      relativePath: 'users',
      searchParameters: [
        ['limit', limit.toString()],
        ['offset', offset.toString()],
      ],
      signal,
      protobufDecodeFunction: (buffer) => UserList.decode(buffer),
    })

    return userList
  }

  public async patchJobSharedAccess(
    jobId: string,
    addEmails: readonly string[],
    removeEmails: readonly string[],
    signal: Signal,
  ): Promise<void> {
    await this.executePatch({
      relativePath: `jobs/${jobId}/shared_access`,
      signal,
      protobufPayloadEncodeFunction: () =>
        SharedAccessChange.encode(
          SharedAccessChange.create({ addEmail: [...addEmails], removeEmail: [...removeEmails] }),
        ).finish(),
    })
  }

  private makeUrl(relativePathParts: readonly string[], searchParameters: readonly SearchParameter[]): URL {
    // The URL _must_ end in a slash - Django requires it.
    const url = new URL(`api/v1/${relativePathParts.join('/')}/`, this.baseUrl)
    for (const [key, value] of searchParameters) {
      url.searchParams.set(key, value)
    }

    return url
  }

  /**
   * Makes request headers that contain the Protobuf content type and the
   * authorization token.
   */
  private makeRequestHeaders(): FetchHeaders {
    const tokenHeaderValue = `Token ${this.apiToken}`

    const commonHeaders = {
      'Content-Type': 'application/x-protobuf',
    }

    // To fix a Safari issue, we use X-Auth-Token in production and then let
    // NGINX forward it to Authorization. Since NGINX is not running in dev, we
    // need to use the original Authorization token directly there.
    return process.env.NODE_ENV === 'production'
      ? { 'X-Auth-Token': tokenHeaderValue, ...commonHeaders }
      : { Authorization: tokenHeaderValue, ...commonHeaders }
  }

  /**
   * Executes a GET request to the API endpoint, handling any errors and
   * deserializing the protobuf success result.
   *
   * @param relativePath - The relative path to the GET API endpoint.
   * @param searchParameters - Any search parameters to add to the request.
   * @param signal - The {@link AbortSignal} to use if the request should be
   * terminated prematurely.
   * @param protobufDecodeFunction - A function that takes a {@link Uint8Array}
   * and decodes it using the generated protobuf deserialization functions.
   *
   * @returns The deserialized protobuf API object.
   */
  private async executeGet<T>({
    relativePath,
    searchParameters = [],
    signal,
    protobufDecodeFunction,
  }: {
    readonly relativePath: string
    readonly searchParameters?: readonly SearchParameter[]
    readonly signal: Readonly<AbortSignal>
    readonly protobufDecodeFunction: (buffer: Uint8Array) => T
  }): Promise<T> {
    const url = this.makeUrl(relativePath.split('/'), searchParameters)
    const request = new Request(url, { method: 'GET', headers: this.makeRequestHeaders(), signal })

    const response = await this.executeFetch(request)
    const contentType = response.headers.get('Content-Type')

    if (contentType !== 'application/x-protobuf') {
      const errorMessage = `Response was successful, but the Content-Type was '${contentType ?? /* istanbul ignore next */ 'null'}', not 'application/x-protobuf'.`
      this.logger.error(errorMessage)
      throw new Error(errorMessage)
    }

    const buffer = await response.arrayBuffer()
    const protobuf = new Uint8Array(buffer)
    const decodedResults = protobufDecodeFunction(protobuf)
    return decodedResults
  }

  /**
   * Executes a PATCH request to the API endpoint, handling any errors, and
   * serializing the input into a protobuf buffer.
   *
   * @param relativePath - The relative path to the GET API endpoint.
   * @param signal - The {@link AbortSignal} to use if the request should be
   * terminated prematurely.
   * @param protobufPayloadEncodeFunction - A function that returns the encoded
   * protobuf input as a {@link Uint8Array}.
   */
  private async executePatch({
    relativePath,
    signal,
    protobufPayloadEncodeFunction,
  }: {
    readonly relativePath: string
    readonly signal: Readonly<AbortSignal>
    readonly protobufPayloadEncodeFunction: () => Uint8Array
  }): Promise<void> {
    const url = this.makeUrl(relativePath.split('/'), [])
    const body = protobufPayloadEncodeFunction()
    const request = new Request(url, { method: 'PATCH', body, headers: this.makeRequestHeaders(), signal })

    await this.executeFetch(request)
  }

  private async executeFetch(request: Request): Promise<Response> {
    try {
      const response = await this.fetchFunction(request)

      if (!response.ok) {
        throw new Error(`Response status: ${response.status.toString()}. Request URL: ${request.url}`)
      }

      return response
    } catch (error_: unknown) {
      const error = error_ as Error

      // If this is a signal abort error it's usually expected because a
      // component is being torn down, so just log an info instead of an error.
      if (error instanceof DOMException && error.name === 'AbortError') {
        this.logger.log(`Request aborted: ${request.url}`)
      } else {
        const message = `${error.message}${error.stack ? `\n${error.stack}` : /* istanbul ignore next */ ''}`
        this.logger.error(message)
      }

      throw error
    }
  }
}
