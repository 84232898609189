import axios from 'axios'
import { apiURL, authHeader, authProtobufHeader } from '../common/utils'
import ApiPB from '../qai_hub/public_api_pb'

/**
 * @typedef {Object} MountState
 * @property {() => boolean} checkMounted
 * @property {AbortController} controller
 *
 * @typedef {[MountState, tearDownMounted: () => void]} UseMountedReturn
 *
 * To handle cancellation of asynchronous components, we need ways to stop
 * asynchronous calls and a gating mechanism in routines already running. We
 * refer to this as a the "mounted" state, and if the state is set to false,
 * the component is no longer mounted and we can cancel any requests. We handle
 * this in a way that is reminiscent of React hooks (e.g. useState). This
 * returns a tuple: the first is an object representing the mounted state (with
 * a controller for asynchronous calls through axios and a function that checks
 * the current state). The second is a function offering a way to tear down the
 * mounted state (e.g. set it to false).
 *
 * @returns {UseMountedReturn}
 */
export function useMounted() {
  let isMounted = true
  let controller = new AbortController()
  const checkMounted = () => {
    return isMounted
  }
  const tearDownMounted = () => {
    isMounted = false
    controller.abort()
  }
  return [{ checkMounted, controller }, tearDownMounted]
}

function isValidId(objectId) {
  return objectId && /^\w+$/.exec(String(objectId))
}

// Follows Rest API closely. Results are fetched asynchronously and returned to
// the user via the thenFunc callback. On failure, catchFunc is called instead.

//
// USER end points
//

export function getAuthUser(mountState, thenFunc, catchFunc) {
  axios
    .get(apiURL('users/auth/user/'), authProtobufHeader({ signal: mountState.controller.signal }))
    .then((res) => {
      let userPb = ApiPB.User.deserializeBinary(new Uint8Array(res.data))
      if (mountState.checkMounted()) {
        thenFunc(userPb)
      }
    })
    .catch((err) => {
      if (mountState.checkMounted() && catchFunc) {
        catchFunc(err)
      }
    })
}

export function getOrganization(orgId, mountState, thenFunc, catchFunc) {
  if (!isValidId(orgId)) {
    catchFunc(`Invalid org ID ${orgId}`)
    return
  }
  axios
    .get(apiURL(`organizations/${orgId}/`), authProtobufHeader({ signal: mountState.controller.signal }))
    .then((res) => {
      let userPb = ApiPB.Organization.deserializeBinary(new Uint8Array(res.data))
      if (mountState.checkMounted()) {
        thenFunc(userPb)
      }
    })
    .catch((err) => {
      if (mountState.checkMounted() && catchFunc) {
        catchFunc(err)
      }
    })
}

export function authLogin(email, password, mountState, thenFunc, catchFunc) {
  const user = { email: email, password: password }
  axios
    .post(apiURL('users/auth/login/'), JSON.stringify(user), {
      headers: { 'Content-Type': 'application/json', 'signal': mountState.controller.signal },
    })
    .then((res) => res.data)
    .then((data) => {
      if (mountState.checkMounted()) {
        thenFunc(data.key)
      }
    })
    .catch(() => {
      if (mountState.checkMounted() && catchFunc) {
        catchFunc()
      }
    })
}

export function authLogout(mountState, thenFunc, catchFunc) {
  axios
    .post(apiURL('users/auth/logout/'))
    .then((res) => res.data)
    .then(() => {
      if (mountState.checkMounted()) {
        thenFunc()
      }
    })
    .catch((err) => {
      if (mountState.checkMounted() && catchFunc) {
        catchFunc(err)
      }
    })
}

const authSSOPromises = {}
export function authSSOLogin(code, mountState, thenFunc, catchFunc) {
  // SSO handling is not idempotent. Each 'code' from OpenID can be used only once and then
  // it becomes invalid. To make this function idempotent, we cache the response promise for
  // each 'code' so it can be reused.
  if (!authSSOPromises[code]) {
    authSSOPromises[code] = axios
      .post(apiURL('users/auth/sso_login/'), JSON.stringify({ code }), {
        headers: { 'Content-Type': 'application/json', 'signal': mountState.controller.signal },
      })
      .then((res) => res.data)
  }

  authSSOPromises[code]
    .then((data) => {
      if (mountState.checkMounted()) {
        thenFunc(data.key)
      }
    })
    .catch((err) => {
      if (mountState.checkMounted() && catchFunc) {
        catchFunc(err)
      }
    })
}

export function changeUserPassword(oldPassword, newPassword, mountState, thenFunc, catchFunc) {
  let messagePb = new ApiPB.UserChangePassword()
  messagePb.setOldPassword(oldPassword)
  messagePb.setNewPassword(newPassword)
  const messageString = new TextDecoder().decode(messagePb.serializeBinary())

  axios
    .put(apiURL('users/auth/change_password/'), messageString, authProtobufHeader())
    .then(() => {
      if (mountState.checkMounted()) {
        thenFunc()
      }
    })
    .catch((err) => {
      const resPb = ApiPB.CreateUpdateResponse.deserializeBinary(new Uint8Array(err.response.data))
      if (mountState.checkMounted() && catchFunc) {
        catchFunc(resPb)
      }
    })
}

export function regenerateToken(mountState, thenFunc, catchFunc) {
  axios
    .post(apiURL('users/auth/regenerate_token/'), '', authHeader())
    .then((res) => {
      const newToken = res.data.key
      if (mountState.checkMounted() && newToken) {
        thenFunc(newToken)
      }
    })
    .catch(() => {
      if (mountState.checkMounted() && catchFunc) {
        catchFunc()
      }
    })
}

export function getUsers(page, rowsPerPage, mountState, thenFunc, catchFunc) {
  axios
    .get(
      apiURL('users/', { limit: rowsPerPage, offset: page * rowsPerPage }),
      authProtobufHeader({ signal: mountState.controller.signal }),
    )
    .then((res) => {
      let userListPb = ApiPB.UserList.deserializeBinary(new Uint8Array(res.data))
      if (mountState.checkMounted()) {
        thenFunc(userListPb)
      }
    })
    .catch(() => {
      if (mountState.checkMounted() && catchFunc) {
        catchFunc()
      }
    })
}

//
// JOB end points
//

export function getJob(jobId, mountState, thenFunc, catchFunc) {
  if (!isValidId(jobId)) {
    catchFunc(`Invalid job ID ${jobId}`)
    return
  }

  axios
    .get(apiURL(`jobs/${jobId}/`), authProtobufHeader({ signal: mountState.controller.signal }))
    .then((res) => {
      const jobPb = ApiPB.Job.deserializeBinary(new Uint8Array(res.data))
      if (mountState.checkMounted()) {
        thenFunc(jobPb)
      }
    })
    .catch((err) => {
      if (mountState.checkMounted() && catchFunc) {
        catchFunc(err)
      }
    })
}

export function getJobResult(jobId, mountState, thenFunc, catchFunc) {
  if (!isValidId(jobId)) {
    catchFunc(`Invalid job ID ${jobId}`)
    return
  }

  axios
    .get(apiURL(`jobs/${jobId}/result/`), authProtobufHeader({ signal: mountState.controller.signal }))
    .then((res) => {
      const jobResultPb = ApiPB.JobResult.deserializeBinary(new Uint8Array(res.data))
      if (mountState.checkMounted()) {
        thenFunc(jobResultPb)
      }
    })
    .catch((err) => {
      if (mountState.checkMounted() && catchFunc) {
        catchFunc(err)
      }
    })
}

export function getJobVizGraph(jobId, mountState, thenFunc, catchFunc) {
  if (!isValidId(jobId)) {
    catchFunc(`Invalid job ID ${jobId}`)
    return
  }

  axios
    .get(apiURL(`jobs/${jobId}/vizgraph/`), authProtobufHeader({ signal: mountState.controller.signal }))
    .then((res) => {
      const vizGraphPb = ApiPB.VizGraph.deserializeBinary(new Uint8Array(res.data))
      if (mountState.checkMounted()) {
        thenFunc(vizGraphPb)
      }
    })
    .catch((err) => {
      if (mountState.checkMounted() && catchFunc) {
        catchFunc(err)
      }
    })
}

export function getJobArtifact(jobId, attemptId, artifactType, mountState, thenFunc, catchFunc) {
  if (!isValidId(jobId) || !isValidId(attemptId) || !isValidId(artifactType)) {
    let err = 'Invalid'
    if (!isValidId(jobId)) {
      err += ` job ID ${jobId}`
    }
    if (!isValidId(attemptId)) {
      err += ` attempt ID ${attemptId}`
    }
    if (!isValidId(artifactType)) {
      err += ` artifact type ${artifactType}`
    }
    catchFunc(err)
    return
  }

  axios
    .get(apiURL(`jobs/${jobId}/device_runs/${attemptId}/artifacts/${artifactType}`), authProtobufHeader())
    .then((res) => {
      const artifact = ApiPB.FileDownloadURL.deserializeBinary(new Uint8Array(res.data))
      if (mountState.checkMounted()) {
        thenFunc(artifact.getUrl())
      }
    })
    .catch((err) => {
      if (mountState.checkMounted() && catchFunc) {
        catchFunc(err)
      }
    })
}

export function getJobSummaries(page, rowsPerPage, mountState, thenFunc, catchFunc, options) {
  let params = { limit: rowsPerPage, offset: page * rowsPerPage }
  if (options) {
    params = { ...params, ...options }
  }
  axios
    .get(apiURL('job_summaries/', params), authProtobufHeader({ signal: mountState.controller.signal }))
    .then((res) => {
      var jobSummaryListPb = ApiPB.JobSummaryList.deserializeBinary(new Uint8Array(res.data))
      if (mountState.checkMounted()) {
        thenFunc(jobSummaryListPb)
      }
    })
    .catch((err) => {
      catchFunc?.(err)
    })
}

export function setJobName(jobId, jobName, mountState, thenFunc, catchFunc) {
  if (!isValidId(jobId)) {
    catchFunc(`Invalid job ID ${jobId}`)
    return
  }

  const data = new ApiPB.JobPublicUpdate()
  // We call the setter separately because the constructor doesn't seem to support property initialization.
  // Passing a dictionary just creates an empty object.
  data.setName(jobName)

  axios
    .patch(
      apiURL(`jobs/${jobId}/`),
      data.serializeBinary(),
      authProtobufHeader({ signal: mountState.controller.signal }),
    )
    .then((res) => {
      if (mountState.checkMounted()) {
        thenFunc(res)
      }
    })
    .catch((err) => {
      if (mountState.checkMounted()) {
        catchFunc(err)
      }
    })
}

export function getJobSharedAccess(jobId, mountState, thenFunc, catchFunc) {
  if (!isValidId(jobId)) {
    catchFunc(`Invalid job ID ${jobId}`)
    return
  }

  axios
    .get(apiURL(`jobs/${jobId}/shared_access/`), authProtobufHeader({ signal: mountState.controller.signal }))
    .then((res) => {
      if (mountState.checkMounted()) {
        const sharedAccessPb = ApiPB.SharedAccess.deserializeBinary(new Uint8Array(res.data))
        thenFunc(sharedAccessPb)
      }
    })
    .catch((err) => {
      if (mountState.checkMounted() && catchFunc) {
        catchFunc(err)
      }
    })
}

export function modifyJobSharedAccess(jobId, addEmails, removeEmails, mountState, thenFunc, catchFunc) {
  if (!isValidId(jobId)) {
    catchFunc(`Invalid job ID ${jobId}`)
    return
  }

  const data = new ApiPB.SharedAccessChange()

  if (addEmails && addEmails.length > 0) {
    data.setAddEmailList(addEmails)
  }

  if (removeEmails && removeEmails.length > 0) {
    data.setRemoveEmailList(removeEmails)
  }

  axios
    .patch(
      apiURL(`jobs/${jobId}/shared_access/`),
      data.serializeBinary(),
      authProtobufHeader({ signal: mountState.controller.signal }),
    )
    .then((res) => {
      if (mountState.checkMounted()) {
        thenFunc(res)
      }
    })
    .catch((err) => {
      if (mountState.checkMounted()) {
        catchFunc?.(err)
      }
    })
}

//
// MODEL end points
//

export function getModel(modelId, mountState, thenFunc, catchFunc) {
  if (!isValidId(modelId)) {
    catchFunc(`Invalid model ID ${modelId}`)
    return
  }

  axios
    .get(apiURL(`models/${modelId}/`), authProtobufHeader({ signal: mountState.controller.signal }))
    .then((res) => {
      const modelPb = ApiPB.Model.deserializeBinary(new Uint8Array(res.data))
      if (mountState.checkMounted()) {
        thenFunc(modelPb)
      }
    })
    .catch((err) => {
      if (mountState.checkMounted() && catchFunc) {
        catchFunc(err)
      }
    })
}

function _getModels(producerId, page, rowsPerPage, mountState, thenFunc, catchFunc, options) {
  let params = {}
  if (page !== null && rowsPerPage !== null) {
    params.limit = rowsPerPage
    params.offset = page * rowsPerPage
  }
  if (producerId !== null) {
    params.producer = producerId
  }
  params = { ...params, ...options }
  axios
    .get(apiURL('models/', params), authProtobufHeader({ signal: mountState.controller.signal }))
    .then((res) => {
      let modelListPb = ApiPB.ModelList.deserializeBinary(new Uint8Array(res.data))
      if (mountState.checkMounted()) {
        thenFunc(modelListPb)
      }
    })
    .catch((err) => {
      if (mountState.checkMounted() && catchFunc) {
        catchFunc(err)
      }
    })
}

export function getModels(page, rowsPerPage, mountState, thenFunc, catchFunc, options) {
  return _getModels(null, page, rowsPerPage, mountState, thenFunc, catchFunc, options)
}

export function getProducedModels(producerId, mountState, thenFunc, catchFunc) {
  return _getModels(producerId, null, null, mountState, thenFunc, catchFunc, {})
}

export function getServiceStatus(mountState, thenFunc, catchFunc) {
  axios
    .get(apiURL('status/'), authProtobufHeader({ signal: mountState.controller.signal }))
    .then((res) => {
      let serviceStatusPb = ApiPB.ServiceStatus.deserializeBinary(new Uint8Array(res.data))
      if (mountState.checkMounted()) {
        thenFunc(serviceStatusPb)
      }
    })
    .catch((err) => {
      if (mountState.checkMounted() && catchFunc) {
        catchFunc(err)
      }
    })
}

//
// FILE management
//

// Downloads the file from the provided URL.
// URL is relative to the API root.
export function downloadFile(relativePath) {
  axios
    .get(apiURL(relativePath), authProtobufHeader())
    .then((res) => {
      const downloadPb = ApiPB.FileDownloadURL.deserializeBinary(new Uint8Array(res.data))
      let link = document.createElement('a')
      link.href = downloadPb.getUrl()
      let fileName = downloadPb.getFilename()
      // Note this attribute won't work if the download path points towards a different host
      // than the page the user is viewing. For example, locally, Django uses a different port,
      // so the downloaded file name will mirror what is on disk rather than this attribute.
      link.setAttribute('download', fileName) //or any other extension
      document.body.appendChild(link)
      link.click()
    })
    .catch((err) => console.log(err))
}
