import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ArticleIcon from '@mui/icons-material/Article'
import CropIcon from '@mui/icons-material/Crop'
import DevicesIcon from '@mui/icons-material/Devices'
import EditIcon from '@mui/icons-material/Edit'
import FlagIcon from '@mui/icons-material/Flag'
import PersonIcon from '@mui/icons-material/Person'
import SummarizeIcon from '@mui/icons-material/Summarize'
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { getJob, setJobName, useMounted } from '../common/restAPI'
import { formatSpec } from '../common/utils'
import ApiPB from '../qai_hub/public_api_pb'
import ListItemWithIcon from './ListItemWithIcon'

function SharedShapeGrid(props) {
  let namedTensorTypesList = props.namedTensorTypesList
  let title = props.title
  let jobPb = props.jobPb
  return (
    <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
      <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
        <ListItemWithIcon caption={title} icon={<CropIcon />}>
          {namedTensorTypesList && namedTensorTypesList.length > 0 ? (
            <>
              {namedTensorTypesList.map((namedTensorType, index) => {
                let name = namedTensorType.getName()
                let spec = namedTensorType.getTensorType()
                return (
                  <Typography key={index}>
                    <code>{name}</code>: {formatSpec(spec)}
                  </Typography>
                )
              })}
            </>
          ) : (
            <>
              {jobPb.getJobState() == ApiPB.JobState.JOB_STATE_FAILED ? (
                <Typography>Not Parsed</Typography>
              ) : (
                <Typography>Not Yet Parsed</Typography>
              )}
            </>
          )}
        </ListItemWithIcon>
      </List>
    </Grid>
  )
}

SharedShapeGrid.propTypes = {
  namedTensorTypesList: PropTypes.any,
  title: PropTypes.string.isRequired,
  jobPb: PropTypes.any,
}

function SharedModelGrid(props) {
  let title = props.title
  let modelPb = props.modelPb
  return (
    <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
      <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
        <ListItemWithIcon caption={title} icon={<UploadFileRoundedIcon />}>
          <div className="vertical-align-elements">
            <div className="scrollable">
              <Link to={`/models/${modelPb.getModelId()}`}>{modelPb.getName()}</Link>
            </div>
            <div style={{ marginLeft: '5px', display: 'inline', fontSize: '0.9em' }}>
              <span className="idbox">{modelPb.getModelId()}</span>
            </div>
          </div>
        </ListItemWithIcon>
      </List>
    </Grid>
  )
}

function getPrecisionString(quantizeDtype) {
  switch (quantizeDtype) {
    case ApiPB.QuantizeDtype.QUANTIZE_DTYPE_INT8:
    case ApiPB.QuantizeDtype.QUANTIZE_DTYPE_UINT8: {
      return 'INT8'
    }
    case ApiPB.QuantizeDtype.QUANTIZE_DTYPE_INT16:
    case ApiPB.QuantizeDtype.QUANTIZE_DTYPE_UINT16: {
      return 'INT16'
    }
    case ApiPB.QuantizeDtype.QUANTIZE_DTYPE_INT4:
    case ApiPB.QuantizeDtype.QUANTIZE_DTYPE_UINT4: {
      return 'INT4'
    }
  }
  return 'Unknown'
}

function getOSName(attributes) {
  const osAttribute = attributes.find((element) => element.startsWith('os:'))
  switch (osAttribute) {
    case 'os:android': {
      return 'Android'
    }
    case 'os:windows': {
      return 'Windows'
    }
    case 'os:ios': {
      if (attributes.includes('format:tablet')) {
        return 'iPadOS'
      }
      return 'iOS'
    }
    case 'macos': {
      return 'macOS'
    }
    // No default
  }
  return ''
}

SharedModelGrid.propTypes = {
  title: PropTypes.string.isRequired,
  modelPb: PropTypes.any,
}

export function JobInfoGrid(props) {
  const [jobPb, setJobPb] = useState(props.jobPb)
  const [isJobBeingRenamed, setIsJobBeingRenamed] = useState(false)
  const [jobLocalName, setJobLocalName] = useState(jobPb.getName())
  const isCompileJob = props.isCompileJob
  const isProfileJob = props.isProfileJob
  const isInferenceJob = props.isInferenceJob
  const isQuantizeJob = props.isQuantizeJob
  const jobId = props.jobId
  const profileDetail = props.profileDetail
  const compileDetail = props.compileDetail
  let versionDetail
  if (profileDetail) {
    versionDetail = profileDetail.getToolVersionsList()
  } else if (compileDetail) {
    versionDetail = compileDetail.getToolVersionsList()
  }
  const hasVersionDetail = versionDetail && versionDetail.length > 0

  const renameJob = () => {
    setIsJobBeingRenamed(false)
    const [mountState] = useMounted()

    setJobName(
      jobId,
      jobLocalName,
      mountState,
      () => {
        // The jobLocalName variable already has the updated value, and that's what will be shown
        // to the user when the input field is hidden.
        getJob(
          jobId,
          mountState,
          (jobPb) => {
            setJobPb(
              isCompileJob
                ? jobPb.getCompileJob()
                : isProfileJob
                  ? jobPb.getProfileJob()
                  : isInferenceJob
                    ? jobPb.getInferenceJob()
                    : jobPb.getQuantizeJob(),
            )
          },
          (err) => {
            console.error(err)
          },
        )
      },
      (err) => {
        console.error(err)
        // Reset the name to what we got in props, aka the previous name of the job.
        setJobLocalName(jobPb.getName())
      },
    )
  }

  const cancelJobRename = () => {
    setIsJobBeingRenamed(false)
    // Reset the name to what we got in props, aka the previous name of the job.
    setJobLocalName(jobPb.getName())
  }

  const renderTime = () => {
    let timesTitleParts = ['Submission']
    let timesElements = [<Typography key="submission">{jobPb.getCreationTime().toDate().toLocaleString()}</Typography>]
    if (jobPb.getCompletionTime()) {
      timesTitleParts.push('Completion')
      timesElements.push(
        <Typography key="completion">{jobPb.getCompletionTime().toDate().toLocaleString()}</Typography>,
      )
    }
    let timesTitle = timesTitleParts.join(' / ') + ' Time'

    return [timesTitle, timesElements]
  }

  if (jobPb) {
    let namedTensorTypesList
    if (!isInferenceJob && jobPb.getTensorTypeList()) {
      namedTensorTypesList = jobPb.getTensorTypeList().getTypesList()
    }
    let [timesTitle, timesElements] = renderTime()

    return (
      <Grid container spacing={2}>
        <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
          <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
            <ListItemWithIcon caption="Name" icon={<ArticleIcon />}>
              {isJobBeingRenamed ? (
                <Stack direction="column">
                  <input
                    autoFocus
                    value={jobLocalName}
                    onChange={(e) => {
                      setJobLocalName(e.currentTarget.value)
                    }}
                  ></input>
                  <Stack direction="row">
                    <Button onClick={renameJob}>Save</Button>
                    <Button onClick={cancelJobRename}>Cancel</Button>
                  </Stack>
                </Stack>
              ) : (
                <Stack direction="row">
                  <div className="scrollable">
                    <Typography color="black">{jobPb.getName()}</Typography>
                  </div>
                  <IconButton
                    onClick={() => {
                      setIsJobBeingRenamed(true)
                    }}
                  >
                    <EditIcon
                      color="primary"
                      style={{
                        height: '20px',
                        margin: '-6px 0 0 -6px',
                      }}
                      className="test"
                    />
                  </IconButton>
                </Stack>
              )}
            </ListItemWithIcon>
          </List>
        </Grid>
        {!isQuantizeJob && (
          <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <ListItemWithIcon caption="Target Device" icon={<DevicesIcon />}>
                <div>{jobPb.getDevice().getName()}</div>
                <div style={{ fontSize: '0.9em' }}>
                  {jobPb.getDevice().getOs() && getOSName(jobPb.getDevice().getAttributesList())}&nbsp;
                  {jobPb.getDevice().getOs()}
                </div>
                <div style={{ fontSize: '0.9em' }}>{jobPb.getDevice().getSocDescription()}</div>
              </ListItemWithIcon>
            </List>
          </Grid>
        )}
        <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ListItemWithIcon caption="Creator" icon={<PersonIcon />}>
              <Typography color="black">{jobPb.getUser().getEmail()}</Typography>
            </ListItemWithIcon>
          </List>
        </Grid>
        <SharedModelGrid
          title={isCompileJob || isQuantizeJob ? 'Source Model' : 'Target Model'}
          modelPb={jobPb.getModel()}
        />
        {isInferenceJob && (
          <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
              <ListItemWithIcon caption={'Input Dataset'} icon={<CropIcon />}>
                <div className="vertical-align-elements">
                  <div className="scrollable">{jobPb.getDataset().getName()}</div>
                  <div style={{ marginLeft: '5px', display: 'inline', fontSize: '0.9em' }}>
                    <span className="idbox">{jobPb.getDataset().getDatasetId()}</span>
                  </div>
                </div>
              </ListItemWithIcon>
            </List>
          </Grid>
        )}
        {!isInferenceJob && (
          <SharedShapeGrid namedTensorTypesList={namedTensorTypesList} title={'Input Specs'} jobPb={jobPb} />
        )}
        <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
          <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
            <ListItemWithIcon caption={timesTitle} icon={<AccessTimeIcon />}>
              {timesElements}
            </ListItemWithIcon>
          </List>
        </Grid>
        {(isCompileJob || isQuantizeJob) && jobPb.getCalibrationDataset() && (
          <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
              <ListItemWithIcon caption={'Quantization Calibration Dataset'} icon={<CropIcon />}>
                <div className="vertical-align-elements">
                  <div className="scrollable">{jobPb.getCalibrationDataset().getName()}</div>
                  <div style={{ marginLeft: '5px', display: 'inline', fontSize: '0.9em' }}>
                    <span className="idbox">{jobPb.getCalibrationDataset().getDatasetId()}</span>
                  </div>
                </div>
              </ListItemWithIcon>
            </List>
          </Grid>
        )}
        {jobPb.getOptions().trim() && (
          <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
              <ListItemWithIcon caption="Options" icon={<FlagIcon />}>
                <Typography>
                  <code>{jobPb.getOptions()}</code>
                </Typography>
              </ListItemWithIcon>
            </List>
          </Grid>
        )}
        {hasVersionDetail && (
          <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
              <ListItemWithIcon caption="Versions" icon={<SummarizeIcon />}>
                {versionDetail.map((toolVersion) => (
                  <div key={toolVersion.getName()}>
                    {toolVersion.getName()} : {toolVersion.getVersion()}
                  </div>
                ))}
                <div>AI Hub : {props.hubVersion}</div>
              </ListItemWithIcon>
            </List>
          </Grid>
        )}
        {isQuantizeJob && (
          <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'hidden' }}>
              <ListItemWithIcon caption="Model Precision" icon={<SummarizeIcon />}>
                <div>Weights: {getPrecisionString(jobPb.getWeightsDtype())}</div>
                <div>Activations: {getPrecisionString(jobPb.getActivationsDtype())}</div>
              </ListItemWithIcon>
            </List>
          </Grid>
        )}
      </Grid>
    )
  }

  return 'Loading Information...'
}

JobInfoGrid.propTypes = {
  jobPb: PropTypes.any,
  jobId: PropTypes.string.isRequired,
  isCompileJob: PropTypes.bool,
  isProfileJob: PropTypes.bool,
  isInferenceJob: PropTypes.bool,
  isQuantizeJob: PropTypes.bool,
  profileDetail: PropTypes.object,
  compileDetail: PropTypes.object,
  hubVersion: PropTypes.string,
}

export function JobTargetInfoGrid({ jobPb, isCompileJob }) {
  if (jobPb) {
    let namedTargetTensorTypesList
    if (isCompileJob && jobPb.getTargetTensorTypeList()) {
      namedTargetTensorTypesList = jobPb.getTargetTensorTypeList().getTypesList()
    }

    return (
      <Grid container spacing={2}>
        <SharedModelGrid title={'Target Model'} modelPb={jobPb.getTargetModel()} />
        {isCompileJob && (
          <SharedShapeGrid namedTensorTypesList={namedTargetTensorTypesList} title={'Input Specs'} jobPb={jobPb} />
        )}
      </Grid>
    )
  }

  return 'Loading Information...'
}

JobTargetInfoGrid.propTypes = {
  jobPb: PropTypes.any,
  isCompileJob: PropTypes.bool,
}
