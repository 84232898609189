import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EmailIcon from '@mui/icons-material/Email'
import ErrorIcon from '@mui/icons-material/Error'
import { Link, Skeleton, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'
import type { ApiFetcher } from '../common/api-fetcher'
import { SharedAccessEmailList } from '../common/shared-access-email-list'
import type { SharedAccess } from '../generated/api/public_api'

export type ShareDialogJobType = 'compile' | 'profile' | 'inference' | 'quantize'

export const TestIds = {
  EMAIL_LIST: 'emailList',
  LOADING_SKELETON: 'loadingSkeleton',
  ERROR_LIST: 'errorList',
}

const ARTIFICIAL_DELAY_MS = 3000

export type ShareDialogProps = {
  readonly apiFetcher: ApiFetcher
  readonly jobId: string
  readonly jobType: ShareDialogJobType
  readonly isOpen?: boolean
  readonly onClose?: () => void
  readonly insertArtificialDelaysForUITesting?: boolean
}

export function ShareDialog({
  apiFetcher,
  jobId,
  jobType,
  isOpen = false,
  onClose,
  insertArtificialDelaysForUITesting,
}: ShareDialogProps) {
  const [emailList, setEmailList] = useState(new SharedAccessEmailList([]))
  const [isLoadingInitialList, setIsLoadingInitialList] = useState(true)
  const [isUpdatingItem, setIsUpdatingItem] = useState(false)
  const [hasErrorInInitialFetch, setHasErrorInInitialFetch] = useState(false)
  const [sharedAccessUpdate, setSharedAccessUpdate] = useState<
    { readonly emailToAdd: string } | { readonly emailToRemove: string }
  >()

  const { dialogTitle, dialogText } = getDialogTitleAndText(jobType)
  const disabled = isUpdatingItem || isLoadingInitialList

  // Load the initial email list of who has access to this job.
  useEffect(() => {
    if (!isOpen) {
      return
    }

    setIsLoadingInitialList(true)

    const onSuccess = (sharedAccess: Readonly<SharedAccess>) => {
      const emails = sharedAccess.email
      setEmailList(SharedAccessEmailList.fromEmails(emails))
      setIsLoadingInitialList(false)
    }

    const abortController = new AbortController()

    const getSharedAccess = async () => {
      try {
        const sharedAccess = await apiFetcher.getJobSharedAccess(jobId, abortController.signal)
        /* istanbul ignore if */
        if (insertArtificialDelaysForUITesting) {
          setTimeout(() => {
            onSuccess(sharedAccess)
          }, ARTIFICIAL_DELAY_MS)
        } else {
          onSuccess(sharedAccess)
        }
      } catch {
        setHasErrorInInitialFetch(true)
        setIsLoadingInitialList(false)
      }
    }

    void getSharedAccess()

    return () => {
      abortController.abort()
    }
  }, [apiFetcher, jobId, insertArtificialDelaysForUITesting, isOpen])

  // Patch any updates to the server.
  useEffect(() => {
    if (sharedAccessUpdate === undefined) {
      return
    }

    const emailToAdd = 'emailToAdd' in sharedAccessUpdate ? sharedAccessUpdate.emailToAdd : undefined
    const emailToRemove = 'emailToRemove' in sharedAccessUpdate ? sharedAccessUpdate.emailToRemove : undefined

    setIsUpdatingItem(true)

    const onSuccess = () => {
      setIsUpdatingItem(false)

      // When adding an email, mark it as done. Otherwise, remove it from the list now.
      if (emailToAdd) {
        setEmailList((list) => list.setStatus(emailToAdd, 'done'))
      } else if (emailToRemove) {
        setEmailList((list) => list.removeEmail(emailToRemove))
      }
    }

    const abortController = new AbortController()
    const modifyJobSharedAccess = async () => {
      try {
        await apiFetcher.patchJobSharedAccess(
          jobId,
          emailToAdd ? [emailToAdd] : [],
          emailToRemove ? [emailToRemove] : [],
          abortController.signal,
        )

        /* istanbul ignore if */
        if (insertArtificialDelaysForUITesting) {
          setTimeout(onSuccess, ARTIFICIAL_DELAY_MS)
        } else {
          onSuccess()
        }
      } catch {
        setIsUpdatingItem(false)
        if (emailToAdd) {
          setEmailList((list) => list.setStatus(emailToAdd, 'hasError'))
        } else if (emailToRemove) {
          setEmailList((list) => list.setStatus(emailToRemove, 'hasError'))
        }
      }
    }

    void modifyJobSharedAccess()

    return () => {
      abortController.abort()
    }
  }, [apiFetcher, jobId, insertArtificialDelaysForUITesting, sharedAccessUpdate])

  const handleAddClick = (emailToAdd: string) => {
    setEmailList(emailList.addEmail(emailToAdd, 'adding'))
    setSharedAccessUpdate({ emailToAdd })
  }

  const handleRemoveClick = (emailToRemove: string) => {
    // Don't push changes if the email in the list wasn't "really" added (it's
    // currently processing or has errors).
    const shouldPushChanges = emailList.getStatus(emailToRemove) === 'done'
    /* istanbul ignore next */
    if (shouldPushChanges) {
      setEmailList(emailList.setStatus(emailToRemove, 'removing'))
      setSharedAccessUpdate({ emailToRemove })
    } else {
      setEmailList(emailList.removeEmail(emailToRemove))
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="share-dialog-title"
      aria-describedby="share-dialog-description"
    >
      <DialogTitle id="share-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="share-dialog-description">{dialogText}</DialogContentText>

        {isLoadingInitialList ? (
          <LoadingSkeleton />
        ) : hasErrorInInitialFetch ? (
          <InitialListLoadError />
        ) : (
          <>
            <LoadedListSection disabled={disabled} emailList={emailList} onRemove={handleRemoveClick} />
            <AddEmailSection disabled={disabled} emailList={emailList} onAdd={handleAddClick} />
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

/**
 * Gets the dialog title and text depending on the job type.
 */
function getDialogTitleAndText(jobType: ShareDialogJobType): {
  readonly dialogTitle: string
  readonly dialogText: string
} {
  switch (jobType) {
    case 'compile': {
      return {
        dialogTitle: 'Share Compile Job',
        dialogText: 'Share this job and its associated source/target models with the following email addresses.',
      }
    }
    case 'profile': {
      return {
        dialogTitle: 'Share Profile Job',
        dialogText:
          'Share this job and its associated assets (the producing job and source/target models) with the following email addresses.',
      }
    }
    case 'inference': {
      return {
        dialogTitle: 'Share Inference Job',
        dialogText:
          'Share this job and its associated assets (the producing job, source/target models, and input/output datasets) with the following email addresses.',
      }
    }
    case 'quantize': {
      return {
        dialogTitle: 'Share Quantize Job',
        dialogText: 'Share this job and its associated source/target models with the following email addresses.',
      }
    }
  }
}

function LoadingSkeleton() {
  return (
    <List data-testid={TestIds.LOADING_SKELETON}>
      {Array.of('first', 'second').map((item) => (
        <ListItem key={item} secondaryAction={<Skeleton variant="rounded" width="1.5rem" height="1.5rem" />}>
          <ListItemText>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }}></Skeleton>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  )
}

function InitialListLoadError() {
  return (
    <List data-testid={TestIds.ERROR_LIST}>
      <ListItem sx={{ gap: 1 }}>
        <ErrorIcon color="error" />
        <ListItemText>Error loading access list.</ListItemText>
        <EmailIcon color="secondary" />
        <Link href="mailto:ai-hub-support@qti.qualcomm.com">Contact support</Link>
      </ListItem>
    </List>
  )
}

function LoadedListSection({
  disabled,
  emailList,
  onRemove,
}: {
  readonly disabled: boolean
  readonly emailList: SharedAccessEmailList
  readonly onRemove: (emailToRemove: string) => void
}) {
  return (
    <List data-testid={TestIds.EMAIL_LIST} sx={{ maxHeight: 300, overflowX: 'hidden', overflowY: 'auto' }}>
      {emailList.items.map(({ email, status }) => (
        <ListItem
          key={email}
          secondaryAction={
            <IconButton
              edge="end"
              aria-label="Remove"
              disabled={disabled}
              onClick={() => {
                onRemove(email)
              }}
            >
              <DeleteIcon color={status === 'hasError' ? 'error' : 'inherit'} />
            </IconButton>
          }
        >
          {/*
           * Show the item with strikethrough if it's being removed.
           * It'll get removed from the list when the update happens.
           */}
          <ListItemText>
            <Typography
              color={status === 'done' ? 'inherit' : status === 'hasError' ? 'error' : 'secondary'}
              sx={status === 'removing' ? { textDecoration: 'line-through' } : {}}
            >
              {email}
            </Typography>
          </ListItemText>
        </ListItem>
      ))}

      {emailList.items.some(({ status }) => status === 'hasError') && (
        <ListItem sx={{ gap: 1 }}>
          <ErrorIcon color="error" />
          <ListItemText>Error in modifying access list.</ListItemText>
          <EmailIcon color="secondary" />
          <Link href="mailto:ai-hub-support@qti.qualcomm.com">Contact support</Link>
        </ListItem>
      )}
    </List>
  )
}

function isValidEmail(email: string): boolean {
  // Taken from MDN here: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
  const pattern =
    /^[\w!#$%&'*+./=?^`{|}~-]+@[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?(?:\.[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?)*$/

  return pattern.test(email)
}

function AddEmailSection({
  disabled,
  emailList,
  onAdd,
}: {
  readonly disabled: boolean
  readonly emailList: SharedAccessEmailList
  readonly onAdd: (emailToAdd: string) => void
}) {
  const [emailInputValue, setEmailInputValue] = useState('')
  const [isEmailInputValueValid, setIsEmailInputValueValid] = useState(false)

  const handleAddClick = () => {
    const emailToAdd = emailInputValue
    if (!emailList.hasEmail(emailToAdd)) {
      onAdd(emailToAdd)
    }

    setEmailInputValue('')
  }

  const handleEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value
    setEmailInputValue(email)
    setIsEmailInputValueValid(isValidEmail(email))
  }

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!disabled) {
      handleAddClick()
    }
  }

  return (
    <ListItem
      component="div"
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="Add"
          onClick={handleAddClick}
          disabled={disabled || !isEmailInputValueValid || emailInputValue === ''}
          sx={{ marginTop: '12px' }}
        >
          <AddIcon />
        </IconButton>
      }
    >
      <Box component="form" sx={{ flexBasis: '100%' }} onSubmit={handleFormSubmit}>
        <TextField
          type="email"
          variant="standard"
          id="email"
          name="email"
          label="Add email"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          fullWidth
          value={emailInputValue}
          error={!isEmailInputValueValid && emailInputValue !== ''}
          onChange={handleEmailInputChange}
        />
      </Box>
    </ListItem>
  )
}
